// this is shared mixins file for all components from the app,
// there you can easily add your own variables / imports or redefine
// existing without touching default packages for future updates

@import 'src/css/mixins.scss'; // import default mixins

// $text: #000;
// $success: green;
$blue: #4e7ca5;
$blueLinks: #09b1ff;
$green: #b9c08b;
$vb-gray-6: #595c97;

.mr-1 {
  margin-right: 0.4rem;
  vertical-align: inherit;
}

.flex {
  display: flex;
}

.ant-menu-inline-collapsed .icon.icon-collapsed-hidden,
.ant-menu-inline-collapsed .ant-menu-submenu-title .ivycon-content-14 {
  right: 1.6rem !important;
  font-size: 2.5rem !important;
  top: 2px !important;
}

.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin-bottom: 0.6rem !important;
}
.ant-menu-item > span {
  color: #7291ad !important;
}
.ant-menu-item-selected > span {
  color: #ffffff !important;
}

.brandingHeader {
  height: 58px;
}
.brandingHeader img {
  height: 98%;
}

.ant-page-header-heading-title {
  color: $blue !important;
}

.ant-card {
  background-color: #fafafa;
}

.ant-tabs,
.ant-card,
.ant-collapse-content-box,
.ant-layout-content p {
  font-size: 16px !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 600;
  // padding-left: 20px;
  // padding-right: 20px;
}
.ant-tabs-tab h4:hover {
  color: $green !important;
}
.ant-tabs-tab-active h4:hover {
  color: $blue !important;
}
.ant-tabs-tab-active {
  background-color: #e8f0ff !important;
}
.ant-tabs-nav .ant-tabs-tab {
  padding-left: 5px;
  padding-right: 5px;
}

li.ant-list-item {
  color: $vb-gray-6 !important;
}

.ant-layout-content a:link,
.ant-layout-content a:visited {
  color: $blueLinks;
  text-decoration: underline;
}
.ant-layout-content a:hover,
.ant-layout-content a:active,
.ant-layout-content a:focus {
  color: $green;
}

.ant-collapse-item-active .ant-collapse-header {
  background-color: $green;
  color: #ffffff;
}
.ant-collapse-item-active .ant-collapse-header h4 {
  color: #ffffff !important;
}

img.left {
  float: left;
  margin-right: 7px;
}
img.right {
  float: right;
  margin-left: 7px;
}
img.bordered {
  border: 1px solid #ccc;
  padding: 3px;
}
img.border-rounded {
  border-radius: 3px;
}
em.tip {
  color: #5f6294 !important;
  cursor: pointer;
}
.ant-form-item-control {
  max-width: 90%;
}

.ant-col.ant-form-item-label {
  max-width: max-content;
}
[data-vb-theme='default'] .ant-col {
  max-width: 100%;
}
.sulphurMapModal {
  width: 1020px !important;
}
.smallText {
  font-size: smaller;
}
.ant-form-item {
  margin-bottom: 10px !important;
}
.ant-input-number {
  width: 100% !important;
}
.ant-steps-item-active {
  background-color: #f7f7f7;
  font-weight: bold;
  font-style: italic;
  color: $blue;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 16px 10px !important;
}
.text-small {
  font-size: small;
}
.text-smaller {
  font-size: smaller;
}
.text-smallest {
  font-size: xx-small;
}
.responsive-chart {
  position: relative;
  height: 100%;
  width: 100%;
}

.ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0 !important;
}

.ant-tabs-tabpane-active .ant-col.ant-col-body {
  background-color: #f7f7f7;
}

.ant-tabs:not(.homeTabs) .ant-tabs-content-holder {
  padding-top: 15px;
}

.profile-header {
  background: url('../public/resources/images/techBg.jpg') center center no-repeat;
}

.calc-steps > div > div:nth-last-child(2) {
  margin-top: 1vh;
}
.calc-steps > div > div:last-child {
  margin-top: 2vh;
  font-weight: bold;
}

.ant-slider:hover .ant-slider-rail {
  background-color: $blue !important;
}
.ant-slider-rail {
  background-color: #aeaee0 !important;
}
.ant-slider-track {
  background-color: $green !important;
}
ant-slider-handle {
  background-color: $blue !important;
}
.ant-slider-handle.ant-tooltip-open {
  border-color: $green !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder {
  color: #cfcfcf !important;
}

// dark theme
[data-vb-theme='dark'] {
  $vb-purple-light: #aeaee0;
  $cft-tab-bg: #3b3966;
  $cft-input-bg: #a6a6d1;
  $cft-extra-txt: #9898c2;
  $cft-form-titles: #b9c08b;
  $vb-black: #141322;
  $vb-gray-dark-2: #7575a3;
  $vb-gray-dark-4: #232135;

  .profile-header {
    background: url('../public/resources/images/techBg-dark.jpg') bottom center no-repeat;
  }
  .ant-typography.ant-typography-secondary {
    color:  $vb-gray-dark-2;
  }
  .brandingHeader {
    background-color: $vb-gray-dark-2;
    color: $green !important;
  }
  .brandingHeader h1 {
    color: $green !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: $cft-tab-bg !important;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-tab {
    background-color: $vb-gray-dark-4;
  }
  .ant-tabs.homeTabs:not(.ant-tabs-right) > .ant-tabs-nav .ant-tabs-nav-list > div:first-child {
    margin-left: 20px;
  }
  .ant-tabs-nav .ant-tabs-tab span.text-primary {
    color: $vb-purple-light !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .text-primary,
  .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active span.text-primary {
    color: $green !important;
  }
  .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active {
    border-left: none;
  }
  .ant-tabs-tabpane-active .ant-col.ant-col-body,
  .ant-steps-item.ant-steps-item-process.ant-steps-item-custom.ant-steps-item-active {
    background-color: $cft-tab-bg;
  }
  .ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding-left: 24px;
    background-color: $cft-tab-bg;
  }
  .ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding-left: 24px;
    background-color: $cft-tab-bg;
  }
  .ant-tabs-tabpane-active h6,
  .ant-tabs-tabpane-active h4,
  .ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane h2,
  .ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane h3,
  .ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane h4,
  .ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane h5,
  .ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane h6 {
    color: $green !important;
  }
  .ant-tabs-tabpane-active .ant-form-item-extra {
    color: $cft-extra-txt;
  }
  .ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
    color: $blue;
  }
  .ant-steps-item.ant-steps-item-process.ant-steps-item-custom.ant-steps-item-active
    .ant-steps-item-title {
    color: $green;
  }
  input {
    color: inherit;
  }
}
