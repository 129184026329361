@import 'mixins.scss';

.head {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
}

.title {
  color: $gray-6;
  margin-bottom: 0;
  flex-shrink: 1;
  margin-right: rem(15);
}

.time {
  text-transform: uppercase;
  margin-left: auto;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: rem(12);
  color: $gray-5;
}

.content {
  margin-bottom: 0;
  color: $gray-4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.glossaryTabs {
  height: 80vh;
  overflow-y: scroll !important;
}

.tabs {
  :global(.ant-tabs-bar) {
    border-bottom: none;
    position: relative;
    padding-left: 25px;
    padding-right: 25px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 3px;
      right: 0;
      height: 1px;
      background-color: $gray-2;
    }
  }

  :global(.ant-tabs-nav) {
    :global(.ant-tabs-tab) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 20px;
      padding-top: 20px;
      margin-right: 25px;
    }

    :global(.ant-tabs-tab-active) {
      color: $blue;
    }
  }

  :global(.ant-tabs-ink-bar) {
    height: 7px;
    border-radius: 5px;
    background-color: $blue;
  }

  :global(.ant-tabs-tabpane) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

// dark theme
[data-vb-theme='dark'] {
  .tabs {
    :global(.ant-tabs-bar) {
      &::before {
        background-color: $dark-gray-4;
      }
    }

    :global(.ant-tabs-nav) {
      :global(.ant-tabs-tab) {
        color: $dark-gray-2;
      }
      :global(.ant-tabs-tab-active) {
        color: $blue;
      }
    }
  }
}
